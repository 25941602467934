import { FC } from 'react';

import { Button, ButtonType, ButtonVariant } from '@admin/atoms/Button';
import { useTranslation } from '@pxr/i18n';

import styles from './LoadingButton.module.scss';

interface Props {
    variant?: ButtonVariant;
    filled?: boolean;
    type?: ButtonType;
    className?: string;
}

export const LoadingButton: FC<Props> = ({
    variant = ButtonVariant.primary,
    type = ButtonType.button,
    filled = false,
    className,
}: Props) => {
    const __translate = useTranslation('common').t;
    const buttonClassName = `${className || ''} ${styles.loading}`;

    return (
        <Button type={type} className={buttonClassName} filled={filled} variant={variant}>
            {__translate`loading`}
        </Button>
    );
};
